@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,700,700i,900,900i&display=swap);

.blueFont {
    color: #1B80D4;
    font-size: 26px;
    font-style: normal;
    font-weight: bold;
}

.whiteFont {
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: bold;
}

* {
    font-family: 'Roboto', sans-serif;
}

html {
    height: 100%;
}

hr {
    background-color: #FFF;
    height: 2px;
    border: 4px;
}

.container,
.row.justify-content-center.align-items-center {
    height: 100%;
    min-height: 100%;
}

.circle {
    width: 10em;
    height: 2.5em;
    border-radius: 50%;
    font-size: 54px;
    color: #ff6c7c;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    border: 1em solid #ff6c7c;
    background: rgb(255, 255, 255);
}

.boxCircle {
    width: 10em;
}

.boxShadow {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    text-align: left;
    display: flex;
    vertical-align: middle;
    line-height: normal;
}

.circleFilled {
    width: 220px;
    height: 220px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ff6c7c;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    border: 3px solid #ff6c7c;
    background: rgb(255, 255, 255);
}

.box-circle-filled {
    width: 220px;
}

.alinha-meio {
    vertical-align: middle;
}

/* NOVO */

.circulo-outline {
    height: 9em;
    width: 9em;
}

.label-cor-topo {
    font-size: 20px;
    font-weight: 300;
}

.texto-meio-circulo-outline {
    font-size: 2.5em;
    font-weight: 900;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    width: 100%;
}

.circulo-fill {
    height: 13em;
    width: 13em;
}

.texto-meio-circulo-fill {
    font-size: 30px;
    left: 50%;
    line-height: 36px;
    position: absolute;
    text-align: center;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
}

.texto-meio-ano {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.w-60 {
    width: 60%;
}

.pontos {
    font-size: 3em;
    -webkit-transform: rotate(90deg) translateY(-15px);
            transform: rotate(90deg) translateY(-15px);
}

.card-circulo {
    height: 10em;
}

body {
    font-family: 'Roboto', sans-serif;
}

.hrCinza {
    color: #C4C4C4;
    line-height: 23px;
}

.backNormal {
    background: rgb(255, 255, 255);
}

/* footer... */

.bg-cinza-escuro {
    background: #5a5a5a;
}

.fonte-doze {
    font-size: 12px;
    line-height: 14px;
}

.area-rodape .form-group .rounded-pill {
    border-radius: 10px !important;
}

@media (max-width: 575.98px) {
    .mb-xs-4 {
        margin-bottom: 1.5rem !important;
    }
}

/* NOVOOO */

.card-titulo {
    color: #1B80D4;
    font-size: 22px;
    font-style: normal;
    font-weight: bold;
}

.idep-azul-grande {
    font-style: normal;
    font-weight: 900;
    font-size: 6em;
    line-height: 1em;
    color: #1B80D4;
}

.texto-azul-medio {
    color: #1B80D4;
    font-size: 56px;
    font-style: normal;
    font-weight: 300;
    line-height: 80px;
}

.txt-gd-brc {
    font-style: normal;
    font-size: 3em;
    line-height: 2em;
    height: 1em;
    color: #ffffff;
    font-weight: 300;
    text-align: left;
    text-transform: uppercase;
}

.lbl-gd-amr {
    font-size: 2em;
    color: #ffbc0a;
    font-weight: 300;
}

.hd-bc-it {
    font-style: italic;
    font-weight: bold;
    font-size: 1.5em;
    line-height: 1em;
    color: #ffffff;
}

.ft-pq-bc {
    font-size: 1em;
    line-height: 1em;
    color: #ffffff;
}

.ic-seta {
    height: 7em;
    width: 23em;
    float: left;
}

.card-item {
    font-size: 0.7em;
    line-height: 0.4em;
    color: #828282;
}

/* Novo */

@media (max-width: 575.98px) {
    .ic-seta {
        height: 7em;
        width: auto;
        float: none;
    }
}

.btn-fw {
    height: 40px;
    width: 112px;
}

.btn-fw:hover {
    border: 1px solid #FFF !important;
}

.bloco-livros {
    height: 470px;
}

@media (max-width: 575.98px) {
    .bloco-livros {
        height: 232px;
    }

    .bloco-livros img {
        position: relative !important;
    }
}

.bloco-livros img {
    opacity: 0.8;
    right: 15px;
    width: 105%;
}

.btn-calculo {
    border-color: transparent !important;
    font-size: 56px;
    font-weight: 300;
    line-height: 80px;
}

.btn-calculo:hover {
    border-color: #FFF !important;
}

.operadores {
    color: #D3D3D3;
    font-size: 64px;
    font-weight: 300;
}

.bottom-label {
    font-size: 14px;
    font-weight: normal;
}

.titulo-anos {
    color: #FFF;
    font-size: 56px;
    font-weight: 300;
    line-height: 80px;
}

.titulo-meta {
    font-size: 56px;
    font-weight: 300;
    line-height: 80px;
}

.borda-meta {
    border-bottom: 5px dotted #FFBC0A;
}

.border-warning {
    border-color: #FFBC0A !important;
}

.titulo-inse-icg {
    font-size: 18px;
    line-height: 22px;
}

.mt-n7 {
    margin-top: -7rem !important;
}

@media (max-width: 575.98px) {
    .mt-n7 {
        margin-top: 0 !important;
    }
}

.card-anos {
    font-size: 26px;
}

.card-texto-grupo {
    color: #828282;
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
}

.borda-dotted {
    border-bottom-color: #C4C4C4 !important;
    border-bottom-style: dotted !important;
    border-bottom-width: 5px !important;
}

.btn-outline-light {
    border-color: #E0E0E0 !important;
}

.secao-escola-titulo {
    font-size: 56px;
    line-height: 88px;
}

.espelha-imagem {
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
}

.texto-cinza-inicio {
    color: #828282;
    font-size: 80px;
    font-style: normal;
    font-weight: normal;
    line-height: 80px;
}

.descricao-cinza-inicio {
    color: #828282;
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
    line-height: 23px;
}

/* Cores */

.cor-amarelo {
    color: #FFBC0A;
}

.cor-azul {
    color: #1B80D4;
}

.cor-cinza {
    color: #828282;
}

.cor-vermelho {
    color: #FF6C7B;
}

.fundo-azul-transparente {
    background: rgba(27, 128, 212, 0.5);
}

.fundo-azul-escuro {
    background: #1B80D4;
}

.min-h-500 {
    min-height: 500px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  font-family: "Roboto";
}

